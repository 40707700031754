import cronstrue from 'cronstrue'
import Recipient from '@/components/inputs/Recipient/Recipient.vue'
import AssetMixin from '@/mixins/asset.mixin'
import FilterTreeview from '@/components/Asset/AssetFilter/FilterTreeview/FilterTreeview.vue'
import { mapMutations } from 'vuex'

const requiredRule = (v) => !!v || 'Required'

export default {
  props: ['item', 'show', 'filter'],
  components: {
    Recipient,
    FilterTreeview
  },
  mixins: [AssetMixin],
  data: () => ({
    id: null,
    dialog: false,
    name: '',
    description: '',
    cron: '',
    localFilter: {},
    submitting: false,
    saved: false,
    validating: false,
    to: [],
    subject: '',
    scheduledReportSelected: 'None',
    scheduledReportOptions: ['None', 'Daily', 'Weekly', 'Monthly'],
    hourSelected: 12,
    minuteSelected: 0,
    hourOptions: [...Array(24).keys()],
    minuteOptions: [...Array(60).keys()],
    dayIntervalSelected: 1,
    dayIntervalOptions: [...Array(16).keys()].slice(1),
    weekdaysSelected: ['MON'],
    weekdaysOptions: [
      {
        text: 'Monday',
        value: 'MON'
      },
      {
        text: 'Tuesday',
        value: 'TUE'
      },
      {
        text: 'Wednesday',
        value: 'WED'
      },
      {
        text: 'Thursday',
        value: 'THU'
      },
      {
        text: 'Friday',
        value: 'FRI'
      },
      {
        text: 'Saturday',
        value: 'SAT'
      },
      {
        text: 'Sunday',
        value: 'SUN'
      }
    ],
    monthlyRadio: 'monthly_1',
    monthdaySelected: '1st',
    monthDayOptions: [
      '1st',
      '2nd',
      '3rd',
      '4th',
      '5th',
      '6th',
      '7th',
      '8th',
      '9th',
      '10th',
      '11th',
      '12th',
      '13th',
      '14th',
      '15th',
      '16th',
      '17th',
      '18th',
      '19th',
      '20th',
      '21st',
      '22nd',
      '23rd',
      '24th',
      '25th',
      '26th',
      '27th',
      '28th',
      '29th',
      '30th',
      '31st',
      'Last day'
    ],
    daysBeforeEomSelected: 1,
    daysBeforeEomOptions: [...Array(32).keys()].slice(1),
    nthWeekdayNthSelected: '1st',
    nthWeekdayNthOptions: ['1st', '2nd', '3rd', '4th', '5th', 'Last'],
    nthWeekdayWeekdaySelected: 'MON',
    nthMonthSelected: 1,
    nthMonthOoptions: [...Array(13).keys()].slice(1)
  }),
  computed: {
    cronHint() {
      let hint = ''
      if (this.cron) {
        try {
          hint = cronstrue.toString(this.cron)
        } catch {
          hint = 'Invalid cron'
        }
      }
      return hint
    },
    isEdit() {
      return !_.isEmpty(_.get(this, 'item.id'))
    },
    rules() {
      const subject = this.scheduledReport ? [requiredRule] : []
      const to = this.scheduledReport
        ? [requiredRule, (v) => (v && v.length > 0) || 'Required']
        : []
      return {
        name: [requiredRule],
        subject,
        to
      }
    }
  },
  watch: {
    show(v) {
      if (this.item == null) {
        this.id = null
        this.name = ''
        this.description = ''
        this.localFilter = this.filter
        this.scheduledReportSelected = 'None'
        this.hourSelected = 12
        this.minuteSelected = 0
        this.dayIntervalSelected = 1
        this.weekdaysSelected = ['MON']
        this.monthlyRadio = 'monthly_1'
        this.monthdaySelected = '1st'
        this.daysBeforeEomSelected = 1
        this.nthWeekdayNthSelected = '1st'
        this.nthWeekdayWeekdaySelected = 'MON'
        this.nthMonthSelected = 1
        this.cron = ''
        this.to = []
        this.subject = ''
      } else {
        if (typeof this.item.settings === 'string') {
          this.item.settings = JSON.parse(this.item.settings)
        }
        this.id = this.item.id
        this.name = this.item.name
        this.description = this.item.description
        this.localFilter = _.get(this.item.settings, 'localFilter', {})
        this.scheduledReportSelected = _.get(
          this.item.settings,
          'scheduledReportSelected',
          'None'
        )
        this.hourSelected = _.get(this.item.settings, 'hourSelected', 12)
        this.minuteSelected = _.get(this.item.settings, 'minuteSelected', 0)
        this.dayIntervalSelected = _.get(
          this.item.settings,
          'dayIntervalSelected',
          1
        )
        this.weekdaysSelected = _.get(this.item.settings, 'weekdaysSelected', [
          'MON'
        ])
        this.monthlyRadio = _.get(
          this.item.settings,
          'monthlyRadio',
          'monthly_1'
        )
        this.monthdaySelected = _.get(
          this.item.settings,
          'monthdaySelected',
          '1st'
        )
        this.daysBeforeEomSelected = _.get(
          this.item.settings,
          'daysBeforeEomSelected',
          1
        )
        this.nthWeekdayNthSelected = _.get(
          this.item.settings,
          'nthWeekdayNthSelected',
          '1st'
        )
        this.nthWeekdayWeekdaySelected = _.get(
          this.item.settings,
          'nthWeekdayWeekdaySelected',
          'MON'
        )
        this.nthMonthSelected = _.get(this.item.settings, 'nthMonthSelected', 1)
        this.cron = this.item.cron
        this.to = this.item.to.map((x) => ({
          id: x,
          mail: x,
          name: x
        }))
        this.subject = this.item.subject
      }
      this.submitting = false
      this.saved = false
      this.dialog = v
    },
    dialog(v) {
      this.$emit('update:show', v)
    }
  },
  methods: {
    updateCron() {
      const m = this.minuteSelected
      const h = this.hourSelected
      let d, month, w
      let cron
      switch (this.scheduledReportSelected) {
        case 'Daily':
          d = `1/${this.dayIntervalSelected}`
          cron = `${m} ${h} ${d} * ?`
          break
        case 'Weekly':
          w = this.weekdaysSelected.join(',')
          cron = `${m} ${h} ? * ${w}`
          break
        case 'Monthly':
          switch (this.monthlyRadio) {
            case 'monthly_1':
              d =
                this.monthdaySelected === 'Last day'
                  ? 'L'
                  : this.monthDayOptions.indexOf(this.monthdaySelected) + 1
              month = `1/${this.nthMonthSelected}`
              cron = `${m} ${h} ${d} ${month} ?`
              break
            case 'monthly_2':
              d = `L-${this.daysBeforeEomSelected}`
              month = `1/${this.nthMonthSelected}`
              cron = `${m} ${h} ${d} ${month} ?`
              break
            case 'monthly_3':
              month = `1/${this.nthMonthSelected}`
              w =
                this.nthWeekdayWeekdaySelected +
                (this.nthWeekdayNthSelected === 'Last'
                  ? 'L'
                  : `#${
                      this.nthWeekdayNthOptions.indexOf(
                        this.nthWeekdayNthSelected
                      ) + 1
                    }`)
              cron = `${m} ${h} ? ${month} ${w}`
              break
          }
          break
        case 'None':
        default:
          return
      }
      this.cron = cron
    },
    validate() {
      this.validating = true

      // validate
      const isValid = this.$refs.form.validate()
      this.validating = false
      if (isValid) {
        this.save()
      }
    },
    save() {
      const settings = {
        localFilter: this.localFilter,
        scheduledReportSelected: this.scheduledReportSelected,
        hourSelected: this.hourSelected,
        minuteSelected: this.minuteSelected,
        dayIntervalSelected: this.dayIntervalSelected,
        weekdaysSelected: this.weekdaysSelected,
        monthlyRadio: this.monthlyRadio,
        monthdaySelected: this.monthdaySelected,
        daysBeforeEomSelected: this.daysBeforeEomSelected,
        nthWeekdayNthSelected: this.nthWeekdayNthSelected,
        nthWeekdayWeekdaySelected: this.nthWeekdayWeekdaySelected,
        nthMonthSelected: this.nthMonthSelected
      }
      const input = {
        name: this.name,
        description: this.description,
        scheduledReport: this.scheduledReportSelected !== 'None',
        cron: this.scheduledReportSelected !== 'None' ? this.cron : null,
        to: this.to.map((x) => x.mail),
        subject: this.subject,
        type:
          this.item == null ? this.currentType.graphTypeName : this.item.type,
        settings: JSON.stringify(settings),
        where: JSON.stringify(this.filterToWhereExpression(this.localFilter)),
        timezone: this.$moment.tz.guess()
      }

      const fields = this.getFieldsGql('read', 'AssetFilter', [
        'id',
        'name',
        'description',
        'scheduledReport',
        'cron',
        'to',
        'subject',
        'type',
        'settings',
        'where',
        'creator'
      ])

      // send by form type
      this.submitting = true
      if (this.isEdit) {
        input.id = this.id
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation updateAssetFilter($input: UpdateAssetFilterInput!) {
                updateAssetFilter(input: $input) {
                  assetFilter {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const assetFilter = _.get(data, 'updateAssetFilter.assetFilter')
            if (assetFilter && assetFilter.id && assetFilter.name) {
              this.saved = true
              this.setAppSnackbar({
                text: `Updated asset filter #${assetFilter.id} ${assetFilter.name} successfully.`,
                color: 'success',
                timeout: 3000
              })

              this.dialog = false
              this.$emit('updated', assetFilter)
            } else {
              this.setAppSnackbar({
                text: `Failed to update asset filter. Error: ${e.toString()}`,
                color: 'error'
              })
            }
          })
          .catch((e) => {
            this.setAppSnackbar({
              text: `Failed to update asset filter. Unknown error: ${e.toString()}`,
              color: 'error',
              timeout: 10000
            })
          })
          .finally(() => {
            this.submitting = false
          })
      } else {
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation createAssetFilter($input: CreateAssetFilterInput!) {
                createAssetFilter(input: $input) {
                  assetFilter {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const assetFilter = _.get(data, 'createAssetFilter.assetFilter')
            if (assetFilter && assetFilter.id && assetFilter.name) {
              this.saved = true
              this.setAppSnackbar({
                text: `Created asset filter #${assetFilter.id} ${assetFilter.name} successfully.`,
                color: 'success',
                timeout: 3000
              })

              this.dialog = false
              this.$router.push({
                name: 'show-asset-filter',
                params: {
                  filterId: assetFilter.id
                }
              })
              this.$emit('created')
            } else {
              this.setAppSnackbar({
                text: `Failed to create asset filter. Error: ${e.toString()}`,
                color: 'error'
              })
            }
          })
          .catch((e) => {
            this.setAppSnackbar({
              text: `Failed to create asset filter. Unknown error: ${e.toString()}`,
              color: 'error',
              timeout: 10000
            })
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    ...mapMutations(['setAppSnackbar'])
  }
}
