var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"assets-table",attrs:{"column":"","fill-height":""}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticClass:"row wrap px-0 pt-1"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('span',{staticClass:"display-1 font-weight-thin",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-2",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":"","data-test":"list-input-search","clearable":""},on:{"paste":function($event){$event.preventDefault();return _vm.searchOnPaste.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isAssetPage && !_vm.isTrash)?[(_vm.isAllAssetsPage)?[(_vm.canCreateAnyAsset)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"btn-new-asset"}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("New asset")])])]}}],null,false,261146559)},[_c('v-list',_vm._l((_vm.assetTypes),function(type){return (_vm.ability.can('create', type.graphTypeName))?_c('v-list-item',{key:type.name,attrs:{"to":{ name: 'create-asset', params: { type: type.name } }}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(type.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" New "+_vm._s(_vm._.startCase(type.graphTypeName))+" ")])],1)],1):_vm._e()}),1)],1):_vm._e(),(_vm.canImportAnyAsset)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"btn-import-assets"},on:{"click":_vm.showImport}},on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,false,3574933761)},[_c('span',[_vm._v("Import")])]):_vm._e()]:[(_vm.ability.can('create', _vm.currentType.graphTypeName))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'create-asset', params: { type: _vm.currentType.name } }}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,3253172818)},[_c('span',[_vm._v("New "+_vm._s(_vm._.startCase(_vm.currentType.graphTypeName)))])]):_vm._e(),(_vm.ability.can('import', _vm.currentType.graphTypeName))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.showImport}},on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,false,3103310460)},[_c('span',[_vm._v("Import")])])]:_vm._e()],_c('import-dialog',{attrs:{"show":_vm.showImportDialog},on:{"update:show":function($event){_vm.showImportDialog=$event}}})]:_vm._e(),(!_vm.isTrash)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.downloading,"id":"btn-download-assets"}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("save_alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])])]}}],null,false,1706048670)},[_c('v-list',[_c('v-list-item',{attrs:{"id":"list-item-export-to-excel"},on:{"click":function($event){return _vm.downloadAssets('Excel')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Export "+_vm._s(_vm._.startCase(_vm.currentType.name))+" to Excel ")])],1)],1),_c('v-list-item',{attrs:{"id":"list-item-export-to-csv"},on:{"click":function($event){return _vm.downloadAssets('Csv')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Export "+_vm._s(_vm._.startCase(_vm.currentType.name))+" to CSV ")])],1)],1),_c('v-list-item',{attrs:{"id":"list-item-export-history-to-excel"},on:{"click":function($event){return _vm.downloadHistories('Excel')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export History to Excel")])],1)],1),_c('v-list-item',{attrs:{"id":"list-item-export-history-to-csv"},on:{"click":function($event){return _vm.downloadHistories('Csv')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export History to CSV")])],1)],1)],1)],1):_vm._e(),(!_vm.isTrash)?_c('customize-column',{attrs:{"headerType":_vm.currentType.graphTypeName}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"btn-reload-assets"},on:{"click":_vm.reload}},on),[_c('v-icon',[_vm._v("cached")])],1)]}}])},[_c('span',[_vm._v("Reload")])]),(!_vm.isTrash)?_c('share',{attrs:{"search":_vm.search,"filter":_vm.filter,"filterId":_vm.filterId}}):_vm._e()],2)],1),(_vm.isAssetFilterPage)?_c('v-card-subtitle',{staticClass:"px-1"},[_c('span',[_vm._v("Asset filter #"+_vm._s(_vm.filterId)+" - ")]),_c('span',[_vm._v("Created by "+_vm._s(_vm._.get(_vm.assetFilter, 'creator')))])]):_vm._e()],1),_c('asset-data-table',{ref:"assetsDatatable",attrs:{"assets":_vm.assets,"assetsCount":_vm.assetsCount,"loading":_vm.$apollo.loading,"options":_vm.options,"filter":_vm.filter,"isTrash":_vm.isTrash},on:{"update:options":function($event){_vm.options=$event},"tagClicked":_vm.tagClicked,"openAssetDialog":_vm.openAssetDialog,"update":_vm.onFilterUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }