import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapGetters } from 'vuex'

export default {
  props: ['headerType'],
  mixins: [GraphQlMixin],
  data() {
    return {
      selectedHeaders: []
    }
  },
  computed: {
    currentAvailableHeaders() {
      return this.headerType ? this.availableHeaders[this.headerType] : []
    },
    availableHeaders() {
      const headers = {}
      this.assetTypes.forEach((at) => {
        headers[at.graphTypeName] = []
        _.forEach(at.headers, (f) => {
          let canRead = false
          let subject = this.headerType
          _.forEach(_.split(f.value, '.'), (fieldName, index) => {
            var fieldNameProperCase = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            canRead = this.ability.can( 'read', this.getSubjectType(at, subject, fieldName), fieldName ) || this.ability.can( 'read', this.getSubjectType(at, subject, fieldNameProperCase), fieldNameProperCase ) //graph v7 wants ProperCase
            subject = fieldName
          })
          if (canRead) {
            headers[at.graphTypeName].push(f)
          }
        })
      })
      return headers
    },
    ...mapGetters('graphql', ['assetTypes'])
  },
  watch: {
    headerType() {
      this.loadHeaders()
    },
    selectedHeaders: {
      handler() {
        let savedHeaders = this.$store.state.settings.assetHeaders
        savedHeaders[this.headerType] = this.selectedHeaders
        this.$store.commit('settings/setAssetHeaders', savedHeaders)
      },
      deep: true
    }
  },
  methods: {
    getSubjectType(assetType, subject, i) {
      var field = assetType.fields.find(
        (f) => f.name.toLowerCase() === subject.toLowerCase()
      )
      if (field) {
        return _.startCase(field.type).replace(' ', '')
      } else {
        return _.startCase(subject).replace(' ', '')
      }
    },
    loadHeaders() {
      const diffFunc = (x, y) => x.text === y.text && x.value === y.value && x.fixed === y.fixed && x.sortable === y.sortable && x.index === y.index
      if (this.headerType) {
        let savedHeaders = this.$store.state.settings.assetHeaders
        let headers = _.get(savedHeaders, this.headerType)

        if (!_.isEmpty(headers)) {
          // remove saved columns that are no longer available
          _.pullAllBy(
            headers,
            _.differenceWith(headers, this.currentAvailableHeaders, diffFunc)
          )
          // append new columns, make sure the headers are unique
          this.selectedHeaders = _.uniqBy(
            _.concat(
              headers,
              _.differenceWith(
                this.currentAvailableHeaders,
                headers,
                diffFunc
              ).map((h) => ({ ...h, show: true }), 'text')
            )
          )
        } else {
          this.selectedHeaders = this.currentAvailableHeaders.map((h) => ({
            ...h,
            show: true
          }))
        }
        this.selectedHeaders = _.orderBy(this.selectedHeaders, [
          'index',
          'fixed',
          'text'
        ])
        savedHeaders[this.headerType] = this.selectedHeaders
        this.$store.commit('settings/setAssetHeaders', savedHeaders)
      }
    }
  },
  created() {
    this.loadHeaders()
  }
}
