import Pagination from '@/components/Pagination/Pagination.vue'
import Memo from '@/components/inputs/Memo/Memo.vue'
import GraphqlMixin from '@/mixins/graphql.mixin'
import AssetMixin from '@/mixins/asset.mixin'
import AssetDetails from '@/components/Asset/AssetDetails/AssetDetails.vue'
import AssetFilter from '@/components/Asset/AssetFilter/AssetFilter.vue'

export default {
  props: ['assets', 'assetsCount', 'loading', 'options', 'filter', 'isTrash'],
  mixins: [AssetMixin, GraphqlMixin],
  components: { AssetFilter, Pagination, Memo, AssetDetails },
  data() {
    return {
      isAssetDialogOpen: false,
      dialogAsset: null,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      localOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      }
    }
  },
  watch: {
    options: {
      handler(v) {
        this.localOptions = v
      },
      deep: true
    },
    localOptions: {
      handler(v) {
        this.$emit('update:options', v)
      },
      deep: true
    }
  },
  computed: {
    items() {
      if (this.loading) {
        return []
      } else {
        return this.assets
      }
    },
    itemFrom() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1
    },
    itemTo() {
      return Math.min(
        this.options.page * this.options.itemsPerPage,
        this.assetsCount
      )
    },
    totalPage() {
      return this.options.itemsPerPage === -1 || this.assetsCount === 0
        ? 1
        : Math.ceil(this.assetsCount / this.options.itemsPerPage)
    },
    displayedHeaders() {
      if (this.isTrash) {
        return [
          {
            text: 'Type',
            value: 'model.assetType',
            fixed: true
          },
          {
            text: 'Name/Phone Number',
            value: 'name',
            fixed: true
          },
          {
            text: 'Serial Number/ICCID',
            value: 'serialNumber',
            fixed: true
          },
          {
            text: 'Region',
            value: 'region'
          },
          {
            text: 'Assignee',
            value: 'assignee.samAccountName'
          },
          {
            text: 'PrimaryUser',
            value: 'primaryUser.samAccountName'
          },
          {
            text: 'Date Deleted',
            value: 'latestDeleteHistory.createdAt'
          },
          {
            text: 'Deleted By',
            value: 'latestDeleteHistory.tokenSubject'
          },
          {
            text: 'Memo',
            value: 'memo'
          }
        ]
      } else {
        const graph = _.get(this, 'currentType.graphTypeName', 'Asset')
        return this.$store.getters['settings/getDisplayedHeaders'](graph)
      }
    }
  },
  methods: {
    openAssetDialog(asset) {
      this.dialogAsset = asset
      this.isAssetDialogOpen = true
      this.$emit('openAssetDialog', asset)
    },
    closeAssetDialog() {
      this.isAssetDialogOpen = false
      this.dialogAsset = null
      this.$emit('openAssetDialog', null)
    },
    tagClicked(tag) {
      this.$emit('tagClicked', tag)
      this.isAssetDialogOpen = false
    },
    previousPage() {
      if (!this.loading && this.options.page > 1) {
        this.options.page--
      }
    },
    nextPage() {
      if (!this.loading && this.options.page < this.totalPage) {
        this.options.page++
      }
    },
    onFilterUpdate(f) {
      this.$emit('update', f)
    }
  },
  mounted() {
    // Close modal with 'esc' key
    window.removeEventListener('keydown', null)
    window.addEventListener(
      'keydown',
      (event) => {
        if (event.code === 'Escape') {
          this.closeAssetDialog()
        }
      },
      true
    )
  },
  beforeDestroy() {
    window.removeEventListener('keydown', null)
  }
}
