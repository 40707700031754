var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"asset-data-table elevation-1 mt-4",attrs:{"headers":_vm.displayedHeaders,"items":_vm.items,"loading":_vm.loading,"footer-props":_vm.footerProps,"options":_vm.localOptions,"server-items-length":_vm.assetsCount,"multi-sort":""},on:{"update:options":function($event){_vm.localOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',[(!_vm.isTrash)?_c('asset-filter',{attrs:{"filter":JSON.parse(JSON.stringify(_vm.filter))},on:{"update":_vm.onFilterUpdate}}):_vm._e(),_c('v-spacer'),_c('v-flex',{staticClass:"justify-end align-end",attrs:{"d-flex":"","align-self-end":""}},[(!_vm.loading && _vm.assetsCount > 0)?_c('div',{staticClass:"caption py-2 mx-4"},[_vm._v(" "+_vm._s(_vm.itemFrom)+"-"+_vm._s(_vm.itemTo)+" of "+_vm._s(_vm.assetsCount)+" ")]):_c('div',{staticClass:"caption py-2 mx-4"},[_vm._v("–")]),_c('div',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page <= 1},on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('div',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page >= _vm.totalPage},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])],1)],1),_c('v-divider')]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){$event.stopPropagation();return _vm.openAssetDialog(item)}}}),_c('v-btn',{attrs:{"icon":"","x-small":"","href":("/assets/" + (_vm.getRouteName(item.__typename)) + "/" + (item.id)),"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [(item.assignee && item.assignee.id && item.assignee.samAccountName)?[_c('router-link',{attrs:{"to":("/users/" + (item.assignee.id))}},[_vm._v(" "+_vm._s(_vm._.get(item.assignee, 'samAccountName', _vm._.get(item.assignee, 'name')))+" ")])]:[_vm._v(" "+_vm._s(_vm._.get(item.assignee, 'samAccountName', _vm._.get(item.assignee, 'name')))+" ")]]}},{key:"item.primaryUser",fn:function(ref){
var item = ref.item;
return [(item.primaryUser && item.primaryUser.id && item.primaryUser.samAccountName)?[_c('router-link',{attrs:{"to":("/users/" + (item.primaryUser.id))}},[_vm._v(" "+_vm._s(_vm._.get(item.primaryUser, 'samAccountName', _vm._.get(item.primaryUser, 'name')))+" ")])]:[_vm._v(" "+_vm._s(_vm._.get(item.primaryUser, 'samAccountName', _vm._.get(item.primaryUser, 'name')))+" ")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm.statusMap, ((item.status) + ".text")))+" ")]}},{key:"item.operationStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm.operationStatusMap, ((item.operationStatus) + ".text")))+" ")]}},{key:"item.pclStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm.pclStatusMap, ((item.pclStatus) + ".text")))+" ")]}},{key:"item.shareStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm.shareStatusMap, ((item.shareStatus) + ".text")))+" ")]}},{key:"item.warrantyExpires",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.warrantyExpires))+" ")]}},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.purchaseDate))+" ")]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.computerUsages, function (x) { return x.value === item.usage; }), 'text'))+" ")]}},{key:"item.pcType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.pcTypes, function (x) { return x.value === item.pcType; }), 'text'))+" ")]}},{key:"item.pcSpecification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.pcSpecifications, function (x) { return x.value === item.pcSpecification; }), 'text'))+" ")]}},{key:"item.useType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.simUseTypes, function (x) { return x.value === item.useType; }), 'text'))+" ")]}},{key:"item.initialDeploymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.initialDeploymentDate))+" ")]}},{key:"item.lastCheckIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.lastCheckIn))+" ")]}},{key:"item.lastInventoryCheck",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.lastInventoryCheck))+" ")]}},{key:"item.initialEnrollDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.initialEnrollDate))+" ")]}},{key:"item.countryIsoCode2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCountry(item.countryIsoCode2))+" ")]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('memo',{attrs:{"text":item.memo,"readonly":true},on:{"update:text":function($event){return _vm.$set(item, "memo", $event)},"tagClicked":_vm.tagClicked}})]}},{key:"item.simSlots",fn:function(ref){
var item = ref.item;
var header = ref.header;
return _vm._l((item.simSlots),function(simSlot){return _c('div',{staticClass:"py-1"},[(header.text === 'Phone Numbers')?[(simSlot.sim)?_c('span',[_vm._v(" "+_vm._s(simSlot.sim.name)+" ")]):_c('i',[_vm._v("-")])]:[_vm._v(" "+_vm._s(simSlot.iMEI)+" ")]],2)})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","text":"","to":("/assets/" + (_vm.getRouteName(item.__typename)) + "/" + (item.id)),"id":("btn-asset-data-table-item-" + (item.id) + "-view")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),(_vm.ability.can('update', item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","text":"","to":("/assets/" + (_vm.getRouteName(item.__typename)) + "/" + (item.id) + "/edit"),"id":("btn-asset-data-table-item-" + (item.id) + "-edit")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]:_vm._e()]}}])}),_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}}),_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","no-click-animation":""},model:{value:(_vm.isAssetDialogOpen),callback:function ($$v) {_vm.isAssetDialogOpen=$$v},expression:"isAssetDialogOpen"}},[(_vm.dialogAsset)?_c('v-card',[_c('v-toolbar',{attrs:{"left":"","flat":"","dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm._.startCase(_vm.dialogAsset.__typename)))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeAssetDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.dialogAsset !== null && _vm.dialogAsset.id !== null)?_c('asset-details',{attrs:{"id":_vm.dialogAsset.id,"assetGraphType":_vm.dialogAsset.__typename},on:{"tagClicked":_vm.tagClicked,"closeAssetDialog":_vm.closeAssetDialog}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }