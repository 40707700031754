import GraphQlMixin from '@/mixins/graphql.mixin'

const getMail = v => _.get(v, 'mail', v)
const validateMail = v =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  )

export default {
  props: {
    label: { type: String, default: 'Recipients' },
    multiple: { type: Boolean, default: () => true },
    selected: Object | Array,
    disabled: { type: Boolean },
    hideDetails: { type: Boolean },
    details: { type: String, default: null },
    singleLine: { type: Boolean }
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      intraAccounts: [],
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      rules: [
        v => !_.isEmpty(v) || 'Required',
        v => {
          if (Array.isArray(v)) {
            for (let item of v) {
              if (!validateMail(getMail(item))) {
                return `Invalid email address ${getMail(item)}`
              }
            }
            return true
          }
          return 'Invalid recipients'
        }
      ]
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selected() {
      this.updateSelected()
    },
    select(v) {
      // clear the query if the searcher is not multiple
      // clear the query if the searcher is multiple, but all options are selected
      if (!this.multiple || _.differenceBy(this.intraAccounts, this.select, 'id').length === 0) {
        this.search = ''
      }
      this.$emit('update:selected', v)
    }
  },
  methods: {
    getMail(v) {
      return getMail(v)
    },
    updateSelected() {
      if (!_.isEqual(this.select, this.selected)) {
        if (!_.isEmpty(this.selected)) {
          if (Array.isArray(this.selected)) {
            this.intraAccounts = [...this.intraAccounts, ...this.selected]
          } else {
            this.intraAccounts.push(this.selected)
          }
          this.intraAccounts = _.uniqBy(this.intraAccounts, 'id')
          this.select = this.selected
        }
        else {
          this.intraAccounts = []
          this.select = []
        }
      }
    }
  },
  apollo: {
    intraAccounts: {
      query() {
        const fields = this.getFieldsGql('read', 'IntraAccount', [
          'id',
          'name',
          'samAccountName',
          'mail'
        ])
        return this.$gql`
          query SearchIntraAccounts($q: String!, $take: Int) {
            intraAccounts(search: $q, take: $take, where: [{ path: "deletedExternally", value: "false" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      variables() {
        return { q: this.search, take: 10 }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ intraAccounts }) {
        intraAccounts = _.get(intraAccounts, 'items', [])
        let result = [..._.toArray(intraAccounts)]
        if (!_.isEmpty(this.select)) {
          if (Array.isArray(this.select)) {
            result = result.concat(this.select)
          } else {
            result.push(this.select)
          }
        }

        if (
          validateMail(this.search) &&
          result.filter(item => item.mail === this.search).length === 0
        ) {
          result.push({
            id: this.search,
            name: this.search,
            mail: this.search
          })
        }

        return result
      },
      result({ error }) {
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelected()
  }
}
