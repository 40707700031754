import cronstrue from 'cronstrue'
import AssetMixin from '@/mixins/asset.mixin'
import FilterTreeview from '@/components/Asset/AssetFilter/FilterTreeview/FilterTreeview.vue'
import { mapMutations } from 'vuex'

export default {
  props: ['show'],
  mixins: [AssetMixin],
  components: { FilterTreeview },
  data: () => ({
    dialog: false,
    assetFilters: [],
    assetFiltersCount: 0,
    options: {
      page: 1,
      itemsPerPage: 2,
      sortBy: ['id'],
      sortDesc: [false]
    },
    footerProps: { 'items-per-page-options': [2, 4, 8, -1] },
    keys: [
      'id',
      'name',
      'description',
      'scheduledReport',
      'cron',
      'to',
      'subject',
      'type',
      'filter',
      'where',
      'creator'
    ],
    search: '',
    debouncing: false,
    debounceTimeout: null,
    showAllFilters: false,
    deleteDialog: false,
    deleteItem: null
  }),
  computed: {
    currentUser() {
      return _.get(this, '$store.state.user.userInfo.subject')
    }
  },
  watch: {
    show(v) {
      if (!v) {
        this.assetFilters = []
        this.assetFiltersCount = 0
      }
      this.dialog = v
    },
    dialog(v) {
      this.$emit('update:show', v)
    },
    search(v) {
      if (v === null) {
        this.search = ''
        return
      }
      this.debouncing = true
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.debouncing = false
        this.options.page = 1
      }, 300)
    }
  },
  methods: {
    getCronHint(cron) {
      let hint = ''
      try {
        hint = cronstrue.toString(cron)
      } catch {
        hint = 'Invalid cron'
      }
      return hint
    },
    edit(item) {
      this.$emit('edit', item)
    },
    copy(item) {
      this.$emit('edit', {
        ...item,
        id: null
      })
    },
    showDeleteDialog(item) {
      this.deleteItem = item
      this.deleteDialog = true
    },
    confirmDelete() {
      if (!_.isEmpty(this.deleteItem)) {
        const input = {
          id: this.deleteItem.id
        }
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation deleteAssetFilter($input: DeleteAssetFilterInput!) {
                deleteAssetFilter(input: $input)
              }
            `,
            variables: { input }
          })
          .then(async({ data }) => {
            this.saved = true
            this.setAppSnackbar({
              text: `Deleted asset filter #${this.deleteItem.id} ${this.deleteItem.name} successfully.`,
              color: 'success',
              timeout: 3000
            })

            const refetchFunc = async(deletedId, refetchCount) => {
              await this.$apollo.queries.assetFilters.refetch()
              if (
                this.assetFilters.find(
                  (af) => af.id.toString() === deletedId.toString()
                ) &&
                refetchCount < 10
              ) {
                setTimeout(() => {
                  refetchFunc(deletedId, refetchCount + 1)
                }, 500)
              }
            }
            refetchFunc(this.deleteItem.id, 0)
          })
          .catch((e) => {
            this.setAppSnackbar({
              text: `Failed to delete asset filter. Unknown error: ${e.toString()}`,
              color: 'error',
              timeout: 10000
            })
          })
          .finally(() => {
            this.submitting = false
          })
      }
      this.deleteDialog = false
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    assetFilters: {
      query() {
        const fields = this.getFieldsGql('read', 'AssetFilter', [
          'id',
          'name',
          'description',
          'scheduledReport',
          'cron',
          'to',
          'subject',
          'type',
          'settings',
          'where',
          'creator'
        ])
        return this.$gql`
          query assetFilters($take: Int, $skip: Int, $orderBy: [OrderBy], $where: [WhereExpression], $search: String!) {
            assetFilters(take: $take, skip: $skip, orderBy: $orderBy, where: $where, search: $search) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        let params = {
          search: this.search,
          take:
            this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip:
            this.options.itemsPerPage === -1
              ? 0
              : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy()
        }
        if (!this.showAllFilters) {
          params.where = [
            {
              path: 'creator',
              comparison: 'equal',
              value: this.currentUser
            }
          ]
        }
        return params
      },
      skip() {
        return (
          !this.show ||
          !this.ability.can('read', 'AssetFilter') ||
          this.debouncing
        )
      },
      update: () => [],
      result({ data, error }) {
        if (data && data.assetFilters) {
          for (let af of data.assetFilters.items) {
            if (typeof af.settings === 'string') {
              af.settings = JSON.parse(af.settings)
            }
            af.isValid =
              JSON.stringify(
                this.filterToWhereExpression(af.settings.localFilter)
              ) === af.where
            if (!af.isValid) {
              console.error('Invalid filter', af)
            }
          }
          this.assetFilters = data.assetFilters.items
          this.assetFiltersCount = data.assetFilters.totalCount

          if (
            Math.ceil(this.assetFiltersCount / this.options.itemsPerPage) <
            this.options.page
          ) {
            this.options.page = 1
          }
        } else if (error) {
          this.setAppSnackbar({
            text: `Failed to load asset filters. Error: ${error.toString()}`,
            color: 'error'
          })
        }
      },
      deep: true
    }
  }
}
