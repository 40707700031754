import AssetMixin from '@/mixins/asset.mixin'
import CreateDialog from '@/components/Asset/AssetFilter/CreateDialog/CreateDialog.vue'
import SaveDialog from '@/components/Asset/AssetFilter/SaveDialog/SaveDialog.vue'
import LoadDialog from '@/components/Asset/AssetFilter/LoadDialog/LoadDialog.vue'
import FilterTreeview from '@/components/Asset/AssetFilter/FilterTreeview/FilterTreeview.vue'
import { mapMutations } from 'vuex'

export default {
  props: ['filter'],
  mixins: [AssetMixin],
  components: {
    CreateDialog,
    SaveDialog,
    LoadDialog,
    FilterTreeview
  },
  data() {
    return {
      localFilter: {},
      showCreateDialog: false,
      showSaveDialog: false,
      showLoadDialog: false,
      editAssetFilterItem: null
    }
  },
  computed: {
    hasFilter() {
      return _.get(this.localFilter, 'filters.length', 0) > 0
    }
  },
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter
      },
      deep: true
    }
  },
  methods: {
    showSaveAssetFilterDialog(item = null) {
      this.editAssetFilterItem = item
      this.showSaveDialog = !this.showSaveDialog
    },
    onFilterCreated() {
      this.showLoadDialog = false
    },
    onFilterUpdated(assetFilter) {
      if (!_.isEmpty(assetFilter)) {
        assetFilter.settings = JSON.parse(assetFilter.settings)
        this.$refs.loadDialog.assetFilters =
          this.$refs.loadDialog.assetFilters.map((af) =>
            af.id === assetFilter.id ? assetFilter : af
          )
      }
    },
    apply(filter) {
      this.localFilter = filter
      this.$emit('update', this.localFilter)
    },
    ...mapMutations(['setAppSnackbar'])
  },
  mounted() {
    this.localFilter = this.filter
  }
}
