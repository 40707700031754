import Recipient from '@/components/inputs/Recipient/Recipient.vue'
import AssetMixin from '@/mixins/asset.mixin'
import { mapMutations } from 'vuex'

export default {
  props: ['search', 'filter', 'filterId'],
  components: { Recipient },
  mixins: [AssetMixin],
  data() {
    return {
      dialog: false,
      link: '',
      recipients: [],
      format: 'Excel',
      loading: false
    }
  },
  watch: {
    dialog() {
      this.link = location.href
    }
  },
  methods: {
    share() {
      this.loading = true

      // flattern recipients
      let input = {
        recipients: this.recipients.map(r => _.get(r, 'mail', r)),
        format: this.format,
        link: this.link,
        dataType: this.currentType.graphTypeName,
        where: JSON.stringify(this.filterToWhereExpression(this.filter))
      }

      if (this.filterId) {
        input.filterId = this.filterId
      } else {
        input.search = this.search
      }

      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
            mutation shareAsset($input: ShareAssetInput!) {
              shareAsset(input: $input)
            }
          `,
          variables: { input }
        })
        .then(() => {
            this.setAppSnackbar({
              text: 'Shared successfully.',
              color: 'success'
            })
        })
        .catch(error => {
          this.setAppSnackbar({
            text: `Failed to share page. ${error}`,
            color: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    copy() {
      this.updateClipboard(this.link)
        .then(() => {
          this.setAppSnackbar({
            text: 'URL copied to clipboard!',
            color: 'success'
          })
        })
        .catch(() => {
          this.setAppSnackbar({
            text: 'Failed to copy to clipboard',
            color: 'error'
          })
        })
    },
    ...mapMutations(['setAppSnackbar'])
  }
}
