import currencyToSymbolMap from 'currency-symbol-map/map'
import VueCountryDropdown from 'vue-country-dropdown'
import AssetMixin from '@/mixins/asset.mixin'
import Date from '@/components/inputs/Date/Date.vue'
import AssigneeSearcher from '@/components/inputs/AssigneeSearcher/AssigneeSearcher.vue'

export default {
  props: ['filter'],
  mixins: [AssetMixin],
  components: {
    VueCountryDropdown,
    AssigneeSearcher,
    Date
  },
  data: () => ({
    showPaths: false,
    search: null,
    items: [],
    locations: [],
    mobiles: [],
    manufacturers: []
  }),
  computed: {
    fields() {
      let fields = []

      // add asset type filter in "All" page
      if (this.isAllAssetsPage) {
        fields.push({
          text: 'Type',
          value: { name: 'model.assetType', type: 'String' }
        })
      }

      if (this.currentType && this.currentType.fields) {
        fields = fields.concat(
          this.currentType.fields
            .filter((f) => f.type.indexOf('[') === -1)
            .filter((field) => {
              var fieldNameProperCase = field.name.length > 1 ? field.name.charAt(0).toUpperCase() + field.name.slice(1) : field.name
              var fieldNameCamelCase = field.name.length > 1 ? field.name.charAt(0).toLowerCase() + field.name.slice(1) : field.name
              return this.ability.can('read', this.currentType.graphTypeName, fieldNameCamelCase) || this.ability.can('read', this.currentType.graphTypeName, fieldNameProperCase)
            })
            .map((f) => ({ text: this.getFieldLabel(f.name), value: f }))
        )

        let manufacturerText = 'Manufacturer'
        let serialNumberText = 'Serial Number'
        let nameText = 'Name'

        if (this.isAllAssetsPage) {
          fields = fields.filter((f) => f.text !== 'Serial Number')
          fields = fields.filter((f) => f.text !== 'Name')

          serialNumberText = 'Serial Number/ICCID'
          nameText = 'Name/Phone Number'
          manufacturerText = 'Manufacturer/Carrier'

          fields.push({
            text: serialNumberText,
            value: { name: 'serialNumber', type: 'String' }
          })
          fields.push({
            text: nameText,
            value: { name: 'name', type: 'String' }
          })
        } else {
          // manufacturer or carrier
          if (this.currentType.graphTypeName === 'Sim') {
            manufacturerText = 'Carrier'
          }
        }

        fields.push({
          text: manufacturerText,
          value: { name: 'model.manufacturer', type: 'Manufacturer' }
        })

        if (!this.ability.can('read', 'Assignee')) {
          fields = fields.filter((f) => f.text !== 'Assignee')
        }
        if (!this.ability.can('read', 'PrimaryUser')) {
          fields = fields.filter((f) => f.text !== 'PrimaryUser')
        }
        if (!this.ability.can('read', 'Model')) {
          fields = fields.filter((f) => f.text !== 'Model')
        }

        fields = fields.filter((f) => f.text !== 'Is Deleted')
        fields = fields.filter((f) => f.text !== 'Is Cloned')
        fields = fields.filter((f) => f.text !== 'Latest Delete History')
        fields = fields.filter((f) => f.text !== 'Jamf Computer')
        fields = fields.filter((f) => f.text !== 'Crowdstrike Computer')
        fields = fields.filter((f) => f.text !== 'Intune Computer')
        fields = fields.filter((f) => f.text !== 'Sim Slot')
        fields = fields.filter((f) => f.text !== 'Mobile')
        fields = fields.filter(
          (f) => f.text !== 'R Mobile Support Password Version'
        )

        // type-specific fields
        switch (this.currentType.graphTypeName) {
          case 'Sim':
            fields.push({
              text: 'Mobile',
              value: { name: 'simSlot.asset', type: 'Mobile' }
            })
            break
          default:
            break
        }
      }
      return _.orderBy(fields, (f) => f.text, 'asc')
    },
    operators() {
      if (!this.filter || !this.filter.field) {
        return [{ text: '=', value: 'equal' }]
      }
      const options = [
        { text: '=', value: 'equal' },
        { text: '>', value: 'greaterThan' },
        { text: '>=', value: 'greaterThanOrEqual' },
        { text: '<', value: 'lessThan' },
        { text: '<=', value: 'lessThanOrEqual' },
        { text: 'contains', value: 'contains' },
        { text: 'starts with', value: 'startsWith' },
        { text: 'ends with', value: 'endsWith' },
        { text: 'in', value: 'in' },
        { text: 'like', value: 'like' }
      ]

      switch (this.currentFieldType) {
        case 'Boolean':
          return options.filter(o => ['equal'].includes(o.value))
        case 'String':
          return options.filter(
            (o) =>
              ![
                'greaterThan',
                'greaterThanOrEqual',
                'lessThan',
                'lessThanOrEqual'
              ].includes(o.value)
          )
        case 'Int':
        case 'Decimal':
        case 'Date':
        case 'DateTimeOffset':
          return options.filter(
            (o) =>
              !['contains', 'startsWith', 'endsWith', 'in', 'like'].includes(
                o.value
              )
          )
        case 'Assignee':
        case 'PrimaryUser':
        case 'Location':
        case 'Company':
        case 'Model':
        case 'Manufacturer':
        case 'Mobile':
        case 'CurrencyCode':
        case 'SimUseType':
        case 'AssetStatus':
        case 'OperationStatus':
        case 'PclStatus':
        case 'ShareStatus':
          return options.filter((o) => ['equal', 'in'].includes(o.value))
        case 'Region':
          return options.filter((o) => ['equal'].includes(o.value))
        default:
          return options
      }
    },
    currentFieldType() {
      return this.getFilterFieldType(this.filter)
    },
    isMultiple() {
      return this.filter.operator && this.filter.operator.value === 'in'
    },
    purchaseCurrencyCodes() {
      return Object.keys(currencyToSymbolMap).map((key) => ({
        text: `${key} (${currencyToSymbolMap[key]})`,
        value: key
      }))
    },
    filterStatuses() {
      // since the "IN_USE" format is only for graphQL and we save "In Use" value in database, we need to filter with the db values
      return this.statuses.map((x) => x.text)
    },
    filterOperationStatuses() {
      return this.operationStatuses.map((x) => x.text)
    },
    filterPclStatuses() {
      return this.pclStatuses.map((x) => x.text)
    },
    filterShareStatuses() {
      return this.shareStatuses.map((x) => x.text)
    },
    filterComputerUsages() {
      return this.computerUsages.map((x) => x.text)
    },
    filterComputerPcTypes() {
      return this.pcTypes.map((x) => x.text)
    },
    filterComputerPcSpecifications() {
      return this.pcSpecifications.map((x) => x.text)
    }
  },
  watch: {
    'filter.operator'(op) {
      if (op) {
        if (
          op.value === 'in' &&
          this.filter.value &&
          !Array.isArray(this.filter.value)
        ) {
          this.filter.value = [this.filter.value]
        } else if (
          op.value !== 'in' &&
          Array.isArray(this.filter.value) &&
          this.filter.value.length > 0
        ) {
          this.filter.value = this.filter.value[0]
        }
      }
    }
  },
  methods: {
    onCountrySelect({ iso2 }) {
      this.filter.value = iso2
    },
    filterFieldChanged() {
      const op = _.get(this.filter, 'field.operator')
      this.filter.value = op === 'in' ? [] : null
    },
    emitRemove() {
      this.$emit('remove')
    },
    modelFilter(item, queryText, itemText) {
      const queryLower = queryText.toLowerCase()
      return (
        _.toLower(item.name).indexOf(queryLower) > -1 ||
        _.toLower(item.modelNumber).indexOf(queryLower) > -1
      )
    }
  },
  apollo: {
    manufacturers: {
      query() {
        const fields = this.getFieldsGql('read', 'Manufacturer', ['id', 'name'])
        return this.$gql`
          query manufacturers($where: [WhereExpression])
          {
            manufacturers(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Manufacturer')
      },
      update({ manufacturers }) {
        return manufacturers.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(
            `Failed to get manufacturers. ${error.toString()}`
          )
        }
      }
    },
    mobiles: {
      query() {
        const fields = this.getFieldsGql('read', 'Mobile', ['id', 'name'])
        return this.$gql`
          query mobiles($where: [WhereExpression])
          {
            mobiles(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Mobile')
      },
      update({ mobiles }) {
        return mobiles.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get mobiles. ${error.toString()}`)
        }
      }
    },
    locations: {
      query() {
        const fields = this.getFieldsGql('read', 'Location', [
          'id',
          'name',
          'region'
        ])
        return this.$gql`
          {
            locations(inUse: true, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Location')
      },
      update({ locations }) {
        return locations.items
      },
      skip() {
        return !this.ability.can('read', 'Location')
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get locations. ${error.toString()}`)
        }
      }
    },
    companies: {
      query() {
        const fields = this.getFieldsGql('read', 'Company', [
          'id',
          'name',
          'nameLocal',
          'companyId'
        ])
        return this.$gql`
          query companies($where: [WhereExpression]) {
            companies(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Company')
      },
      update({ companies }) {
        return companies.items
      },
      skip() {
        return !this.ability.can('read', 'Company')
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get companies. ${error.toString()}`)
        }
      }
    }
  }
}
